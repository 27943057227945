import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import i18n from './utils/i18n.js';

const Loading = lazy(() => import("./components/Loading.js"));
const Landing = lazy(() => import("./pages/Landing.js"));
const Login = lazy(() => import("./pages/Login.js"));
const Verify = lazy(() => import("./pages/Verify.js"));
const Register = lazy(() => import("./pages/Register.js"));
const Home = lazy(() => import("./pages/Home.js"));
const Upload = lazy(() => import("./pages/Upload.js"));
const PointShop = lazy(() => import("./pages/PointShop.js"));
const RewardInfo = lazy(() => import("./pages/RewardInfo.js"));
const RedeemDetails = lazy(() => import("./pages/RedeemDetails.js"));
const Profile = lazy(() => import("./pages/Profile.js"));
const ProfileDetails = lazy(() => import("./pages/ProfileDetails.js"));
const AddressPage = lazy(() => import("./pages/AddressPage.js"));
const AddAddress = lazy(() => import("./pages/AddAddress.js"));
const UpdateAddress = lazy(() => import("./pages/UpdateAddress.js"));
const HelpCenter = lazy(() => import("./pages/HelpCenter.js"));
const Preferences = lazy(() => import("./pages/Preferences.js"));
const Tiering = lazy(() => import("./pages/Tiering.js"));
const Submissions = lazy(() => import("./pages/Submissions.js"));
const Cart = lazy(() => import("./pages/Cart.js"));
const Success = lazy(() => import("./pages/RedeemSuccess.js"));
const HuatLong = lazy(() => import("./pages/HuatLongLong.js"));
const Language = lazy(() => import("./pages/Language.js"));
const Referral = lazy(() => import("./pages/Referral.js"));

function App() {
  const [language, setLanguage] = useState(localStorage.getItem("language"));

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
    }
  }, [language]);

  return (
    <div className="App" >
      {" "}
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/landing" element={<Landing />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/login" element={<Login />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/pointShop" element={<PointShop />} />
            <Route path="/redeem/details" element={<RedeemDetails />} />
            <Route path="/reward/info" element={<RewardInfo />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/details" element={<ProfileDetails />} />
            <Route path="/allAddress" element={<AddressPage />} />
            <Route path="/add/address" element={<AddAddress />} />
            <Route path="/update/address" element={<UpdateAddress />} />
            <Route path="/helpCenter" element={<HelpCenter />} />
            <Route path="/preferences" element={<Preferences />} />
            <Route path="/tiers" element={<Tiering />} />
            <Route path="/history" element={<Submissions />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/success" element={<Success />} />
            <Route path="/huatlonglong" element={<HuatLong />} />
            <Route path="/language" element={<Language lang={language} setLang={setLanguage} />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="*" element={<Navigate to="/landing" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div >
  );
}

export default App;
